<template>
    <div>
        <div class="bag">
            <div class="dengluimg" v-if="inwidth > 768">
                <div class="dengluimg_bgc">
                    <div class="dengluimg_box">
                        <img class="dengluimg_box_img" src="../../../../static/wenchang/6.png" alt="">
                        <div class="input1">
                            <div class="phone">手机号</div>
                            <div>
                                <el-input v-model="input" placeholder="请输入手机号"></el-input>
                            </div>
                        </div>
                        <div class="input2">
                            <div class="phone">验证码</div>
                            <div>
                                <el-input v-model="code" placeholder="请输入验证码">
                                    <template slot="append">
                                        <el-button v-if="nub == 60" type="primary" @click="getcodes()"
                                            v-loading="loading">获取验证码</el-button>
                                        <el-button v-else type="primary" v-loading="loading">{{ nub }}</el-button>
                                    </template>
                                </el-input>
                            </div>
                        </div>
                        <div class="titip">手机号直接登录，无需注册</div>
                        <div class="loginimg" @click="gotoupdata">
                            <img src="../../../../static/wenchang/7.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="dengluimg" v-else>
                <div class="dengluimg_bgc">
                    <div style="text-align: center;padding: 20px">
                        <img src="../../../../static/wenchang/账号登录.png" alt="">
                    </div>
                    <div class="input3">
                        <div class="phone1">手机号</div>
                        <div>
                            <el-input v-model="input" placeholder="请输入手机号"></el-input>
                        </div>
                    </div>
                    <div class="input3">
                        <div class="phone1">验证码</div>
                        <div>
                            <el-input v-model="code" placeholder="请输入验证码">
                                <template slot="append">
                                    <el-button v-if="nub == 60" type="primary" @click="getcodes()"
                                        v-loading="loading">获取验证码</el-button>
                                    <el-button v-else type="primary" v-loading="loading">{{ nub }}</el-button>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <div class="titip1">手机号直接登录，无需注册</div>
                    <div class="loginimg1" @click="gotoupdata">
                        <img src="../../../../static/wenchang/7.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getcode, login, findOne, useradd, artworkadd } from "../../../util/api"
export default {
    name: 'science_child1',
    data() {
        return {
            input: '',
            code: '',
            nub: 60,
            inwidth: window.innerWidth,
            loading: false,
        }
    },
    mounted() {
        let that = this
        window.addEventListener('scroll', function () {
        }, true);
    },
    destroyed() {
        let that = this
        // window.removeEventListener("scroll", that.onscroll(), true);
    },
    methods: {
        scrollToAnchor() {
            this.$router.push('/')
        },
        gotoupdata() {
            login({
                code: this.code,
                phone: this.input
            }).then((res) => {
                if (res.data.message == "无效验证码") {
                    this.loading = false
                    this.$message({
                        message: res.data.message,
                        type: 'warning'
                    });
                } else {
                    this.loading = false
                    if (this.inwidth > 768) {
                        localStorage.setItem('phone', this.input);
                        findOne({ phone: this.input }).then((res) => {
                            if (res.data) {
                                localStorage.setItem('useinfo', res.data.id);
                            } else {
                                useradd({ phone: this.input }).then((res) => {
                                    localStorage.setItem('useinfo', res.data);
                                    artworkadd({
                                        phone: this.input,
                                        userId: res.data,
                                    }).then((res) => {
                                        location.reload();
                                    })
                                })
                            }
                            this.$router.push('/science_child2')
                        })
                    } else {
                        this.$router.push('/')
                        localStorage.setItem('phone', this.input);
                        findOne({ phone: this.input }).then((res) => {
                            if (res.data) {
                                localStorage.setItem('useinfo', res.data.id);
                            } else {
                                useradd({ phone: this.input }).then((res) => {
                                    localStorage.setItem('useinfo', res.data);
                                })
                            }
                            location.reload();
                        })
                    }
                }
            })
        },
        getcodes() {
            const regExp = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
            if (!regExp.test(this.input)) {
                this.$message({
                    showClose: true,
                    message: '手机号不正确！',
                    type: 'error'
                });
                return
            }
            this.loading = true
            getcode({ phone: this.input }).then((res) => {
                console.log(res, 'res');
                if (res.data.message) {
                    this.$message({
                        message: res.data.message,
                        type: 'warning'
                    });
                    this.loading = false
                    return
                } else {
                    this.$message({
                        message: '发送成功！',
                        type: 'success'
                    });
                    let countdown = setInterval(() => {
                        if (this.nub > 0) {
                            console.log(this.nub); // 以控制台输出倒计时的秒数
                            this.nub--;
                        } else {
                            clearInterval(countdown); // 清除定时器
                            // 可在此处执行获取验证码的逻辑
                            this.nub = 60

                        }
                    }, 1000);
                }
                this.loading = false
            })
        }
    }
}
</script>
<style lang="less" scoped>
.section {
    width: 100%;
    max-width: 1920px;
    height: 241px;
}

a {
    text-decoration: none;
}

.headrt {
    display: none;
}

.headli {
    display: inline-block;
    height: 71px;
    padding: 0 33px;
    line-height: 71px;
    cursor: pointer;
    color: #fff;
}

.headbox {
    width: 100%;
    height: 71px;
    background: #2C88CC !important;
    z-index: 2000;
    color: #333;
}

.header {
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.headlt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.headlt img {
    width: 1.22rem;
    display: block;
    cursor: pointer;
}

.headamo {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headlist {
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    font-size: 18px;
}

.headlist .navitem {
    margin: 0 0.30rem;
    /* padding:.1rem 0; */
    line-height: 1rem;
    /* border-bottom: .03rem solid transparent; */
    font-size: .16rem;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    cursor: pointer;
    position: relative;
    min-width: .7rem;
    height: 1rem;
    font-weight: bold;
}

.nav_cont {
    width: 100%;
    position: absolute;
    text-align: center;
    color: #333;
    transition: color .3s ease-in-out;
    text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}


.navitem:hover .nav_cont {
    color: #ffb900;
}

.headlist .navctive .nav_cont {
    padding-top: 0px;
    /* border-bottom: .03rem solid #e60012; */
    color: #ffb900;
}

.headlist .navctive.navctive1 {
    padding-top: 0px;
    /* border-bottom: .03rem solid #e60012; */
    color: #ffb900;
}

.headlist .navctive1 {
    margin: 0 auto;
}

.headrt {
    height: 1.0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .16rem;
    display: none;
}

.headrt div {
    padding: .1rem 0;
    margin-left: 0.1rem;
    cursor: pointer;
}

.headrt img {
    width: .3rem;
    margin-left: 0.3rem;
}

.headrt .headlogo {
    font-weight: bold;
}

.hs_color {
    color: #333 !important;
    border-bottom: .01rem solid #000;
}

.dis_play {
    display: none;
}

.logo1 {
    display: block !important;
}

.logo2 {
    display: none !important;
}

.hs_color .logo1 {
    display: none !important;
}

.hs_color .logo2 {
    display: block !important;
}

.home_icon img {
    /* width: .35rem; */
    height: .2rem;
    margin: 0 0.1rem;
    cursor: pointer;
}

.home_txt .a_class {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-left: .5rem;
    margin-right: .5rem;
    cursor: pointer;
    text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}


.phFooterLi {
    display: flex;
    align-items: center;
}

.footlt2 {
    position: relative;
}

.footerBg {
    position: absolute;
    width: 30%;
    left: 1.5rem;
    top: -0.2rem;
}

.footer {
    position: relative;
}

.phcall {
    font-size: 0.3rem;
    color: #ffb900;
    font-weight: bold;
    margin-left: 10px;

}

.phAddress {
    color: #666666;
    font-size: 0.16rem;
    margin-top: 0.3rem;
}

.backWith {
    background-color: #f9f9f9;
}

.footma_ul2 {
    display: flex;
    align-items: center;
    margin-left: .5rem;
}

.footma_ul2 img {
    width: 0.9rem;
    display: block;
    margin-bottom: 0.1rem;
}

.footma_ul2>div {
    text-align: center;
    color: #666666;
    font-size: 0.16rem;
    margin-left: 0.3rem;
}

/* 
第二版 */
.isfooter {

    .footbox,
    .footbtm {
        background: #000;
    }

    .footul li div,
    .footma_ul li {
        color: #fff;
    }

    .footma_ul li img {
        background: #fff;
    }

}

.headbox3 {
    background: rgba(255, 255, 255, 0);
    color: #fff;
}

.headbox3 .nav_cont {
    color: #fff;
    text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}

.headbox3 .home_txt .a_class {
    color: #fff;
    text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}

.isfooter {

    .footbox,
    .footbtm {
        background: #222;
    }

    .footul3 {
        margin-left: 0;
        margin-right: 15%;
    }

    .footul3 li .libor {
        margin-top: 0.1rem;
        width: 0.19rem;
        height: 1px;
        background: #ffb900;
    }

    .footul3 li div {
        color: #898989;
    }

    .footul3 li .litop {
        color: #ffffff;
    }

    .footlt3 .footma_ul {
        margin-top: 0.3rem;
    }

    .footlt3 .footma_ul li {
        color: #898989;
    }

    .foot_cont3 {
        border-top: 1px solid #333333;
    }
}

/*当浏览器尺寸匹配时候的代码：*/
@media screen and (max-width:1410px) {
    .headlist .navitem {
        margin: 0 0.14rem;
    }

    .footul li {
        padding: 0 0.1rem;
        margin-left: 0.2rem;
    }

    .footul {
        margin-left: 5%;
    }

    .footul3 {
        margin-left: 0;
        margin-right: 5%;
    }
}

@media screen and (max-width:1200px) {
    .footul li {
        padding: 0 0.1rem;
        margin-left: 0.15rem;
    }

    .footul {
        margin-left: 2%;
    }

    .footul3 {
        margin-left: 0;
        margin-right: 2%;
    }
}

@media screen and (max-width:768px) {
    .headamo {
        justify-content: flex-end;
    }

    .lines {
        width: 25px;
        position: relative;
        z-index: 9999;
    }

    .lines span {
        width: 100%;
        height: 2px;
        background: #000;
        display: block;
        margin-bottom: 4px;
    }

    .lines span:last-child {
        margin-bottom: 0;
    }

    .headnav {
        width: 100%;
        padding: 1rem 0 3rem;
        position: absolute;
        top: 4.9rem;
        left: 0;
        background: #fff;

    }

    .navitem {
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        padding: .25rem 0;
        color: #666;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
    }

    .navitem:hover {
        color: #a21003;
    }

    .navctive {
        background: #eee;
    }

    .headnav {
        top: 0;
    }

    .headlt {
        position: relative;
        z-index: 999;
    }

    .headbox {
        background: #fff;
        padding-bottom: .1rem;
        display: block;
    }

    .lines {
        margin-top: 15px;
    }

    .headlt {
        margin-top: 0;
    }

    .footul {
        display: none;
    }

    .footer .footlt {
        width: auto;
        display: flex;
        justify-content: space-between;
    }

    .footer .footlogo img {
        width: 1.3rem;
    }

    .footma_ul {
        flex: 1;
        margin-left: .5rem;
    }

    .footma_ul li {
        line-height: 24px;
    }

    .footma_ul li img {
        /* width: .25rem; */
        height: 0.9rem;
        margin-right: .2rem;
        margin-top: 2px;
    }

    .foot_cont div {
        width: 100%;
        text-align: center;
    }

    .foot_cont div:last-child {
        display: none;
    }

    .footbtm {
        height: .6rem;
        line-height: .6rem;
    }

}

@media screen and (max-width:480px) {
    .lines {
        margin-top: 6px;
    }

    .footer .footlogo img {
        width: 2rem;
    }

    /* .footma_ul li img{
		width: .35rem;
		height: .35rem;
	} */

}
</style>
<style scoped>
.headlt {
    position: relative;
    z-index: 999;
}

.headbox {
    background: #fff;
    padding-bottom: .1rem;
    display: block;
}

.lines {
    margin-top: 15px;
}

.headlt {
    margin-top: 0;
}

/deep/ .el-input__inner {
    background-color: #FAFAFA !important;
}

.el-button--primary {
    color: #FFF !important;
    background-color: #389EDF !important;
    border-color: #389EDF !important;
    height: 40px;
}

.bag {
    margin-top: 71px;
    position: relative;
    background-image: url("https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/backbround.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: calc(100vh - 71px);
    z-index: 1;
}

.dengluimg {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dengluimg_bgc {
    z-index: 2;
    width: 1174px;
    height: 726px;
    background-image: url("https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/login.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dengluimg_box {
    width: 666px;
    height: 698px;
    position: absolute;
    right: 0;
}

.dengluimg_box_img {
    position: absolute;
    top: 107px;
    left: 180px;
}

@media screen and (max-width:5000px) {
    .input1 {
        position: absolute;
        top: 213px;
        left: 117px;
        width: 418px;
        height: 52px;
    }

    .input2 {
        position: absolute;
        top: 364px;
        left: 117px;
        width: 418px;
        height: 52px;
    }
}


.phone {
    font-weight: 500;
    font-size: 20px;
    color: #1A1A1A;
    margin-bottom: 23px;
}

.titip {
    position: absolute;
    top: 498px;
    left: 117px;
    font-weight: 400;
    font-size: 16px;
    color: #4FADEA;
}

.loginimg {
    position: absolute;
    top: 571px;
    left: 243px;
    cursor: pointer;
}

@media screen and (max-width:480px) {
    .dengluimg_bgc {
        z-index: 2;
        width: 360px;
        height: 630px;
        background-image: url("../../../../static/wenchang/34.png");
    }

    .input3 {
        width: 100%;
        padding: 0 20px;
    }

    .phone1 {
        font-weight: 600;
        font-size: 15px;
        color: #1A1A1A;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .titip1 {
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        color: #4FADEA;
        padding: 20px 20px;
    }

    .loginimg1 {
        margin-top: 15px;
        padding: 20px 20px;
        cursor: pointer;
        text-align: center;
    }
}
</style>